import React from "react";
import useScrollRefs from "../useScrollRefs";
import "./services.css";
import adviserImage from "./adviser.jpg"; 
import { Link } from "react-router-dom";

const Services = () => {
  const { servicesRef, adviserRef } = useScrollRefs();

  const services = [
    {
      id: 1,
      title: "Accredited Employer Work Visa",
      description: "Work visa for employees of accredited employers in New Zealand.",
      link: "/AEWV"
    },
    {
      id: 2,
      title: "Visitor Visa",
      description: "Visa for tourists and short-term visitors to explore New Zealand."
    },
    {
      id: 3,
      title: "Student Visa",
      description: "Visa for international students to study in New Zealand."
    },
    {
      id: 4,
      title: "Residence Visa",
      description: "Visa for individuals seeking to live permanently in New Zealand."
    },
    {
      id: 5,
      title: "Partnership Visa",
      description: "Visa for partners of New Zealand citizens, residents or AEWV holder."
    },
    {
      id: 6,
      title: "Working Holiday Visa",
      description: "Visa for young people to travel and work in New Zealand for up to a year."
    }
  ];

  const immigrationAdviser = {
    name: "Angeli Lagonoy",
    subname: "Licensed Immigration Adviser  - License Number: 202200829",
    image: adviserImage,
    description:
      "Before founding Align Immigration Solutions, Angeli served as a Licensed Immigration Adviser for two years. She also spent seven years in the healthcare industry, where she honed her advocacy skills and developed a deep passion for helping others. Inspired by her own experiences as a migrant, Angeli pursued a career in Immigration Advice with the goal of assisting others in achieving their immigration dreams in New Zealand. Angeli currently resides in Christchurch with her partner and their dog, a Golden Retriever named Scotty, who brings joy and activity to their spare time."
  };

  return (
    <div className="serviceBox">
       <h2>Our Licensed Immigration Adviser</h2>
      <div className="adviser-container" ref={adviserRef}>
        <img src={immigrationAdviser.image} alt={immigrationAdviser.name} className="adviser-image" />
        
        <div className="adviser-description">
          <h3>{immigrationAdviser.name}</h3>
          <p>{immigrationAdviser.subname}</p>
          
          <p>{immigrationAdviser.description}</p>
        </div>
      </div>
      <h2>Our Services</h2>
      <div className="services-container" ref={servicesRef}>
        {services.map((service) => (
          <div key={service.id} className="service-box">
             <h3>
              <Link to={service.link}>{service.title}</Link>
            </h3>
            <p>{service.description}</p>
          </div>
        ))}
      </div>
     
    </div>
  );
};

export default Services;
