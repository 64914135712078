
import React from 'react';
import './welcome.css';
import backgroundImage1 from './welcome.jpg';
import backgroundImage2 from './welcome1.jpg';
import backgroundImage3 from './welcome2.jpg';
import backgroundImage4 from './welcome3.jpg';

const Banner = () => {
  return (
    <div className="banner">
      <div className="background-image first" style={{ backgroundImage: `url(${backgroundImage1})` }}></div>
      <div className="background-image second" style={{ backgroundImage: `url(${backgroundImage2})` }}></div>
      <div className="background-image third" style={{ backgroundImage: `url(${backgroundImage3})` }}></div>
      <div className="background-image fourth" style={{ backgroundImage: `url(${backgroundImage4})` }}></div>
      
    </div>
  );
};

export default Banner;
