import React from 'react';

function Intro() {
  return (
    <div className="banner-content">
      <h1>Aligning Your Dream with a Perfect Solution!</h1>
      <p>Unlock new opportunities with expert advice and seamless visa solutions. Let’s make your visa journey a breeze.</p>
    </div>
  );
}

export default Intro;
