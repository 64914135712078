import React from "react";
import "./footer.css";
import '@fortawesome/fontawesome-free/css/all.min.css';
import logo from './logo.svg'; // Ensure the correct path to your logo

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <img src={logo} alt="Align Immigration Solutions" className="footer-logo" />
          
          <h3>Align Immigration Solutions</h3>
          <p>Christchurch, New Zealand</p>
          <p>Email: solutions@alignimmigration.co.nz</p>
          <p>Phone: +64 27 277 2724</p>
        </div>
        <div className="footer-right">
          <p>Follow Us:</p>
          <div className="social-icons">
            <a
              href="https://www.facebook.com/alignimmigrationnz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/alignimmigrationnz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; 2024 Align Immigration Solutions. All Rights Reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
