import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom"; 
import App from "./App";

import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCK-yE7UwOt_HOI8GP6sMz4LcI9mVVo7i4",
  authDomain: "aling-immigration-solution.firebaseapp.com",
  databaseURL: "https://aling-immigration-solution-default-rtdb.firebaseio.com",
  projectId: "aling-immigration-solution",
  storageBucket: "aling-immigration-solution.appspot.com",
  messagingSenderId: "626197828008",
  appId: "1:626197828008:web:75cd4a5fb972ef6c1b4d9d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>
);
