import React from "react";
import { Banner, Intro, Links, Services, Contact } from "../components";

const Home = () => {
  return (
    <div>
      <Banner />
      <Intro/>
      <Services />
      <Contact/>
      <Links/>
    </div>
  );
};

export default Home;
