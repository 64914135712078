// src/pages/AEWV.js
import React from 'react';
import './aewv.css';  // Import the CSS file for AEWV
import aewvImage from './aewv.jpg';  // Ensure you have the correct path to your image

const AEWV = () => {
  return (
    <div className="aewv-container">
      <h1>Accredited Employer Work Visa (AEWV)</h1>
      <img src={aewvImage} alt="Accredited Employer Work Visa" className="aewv-image" />
      <p>
        The Accredited Employer Work Visa (AEWV) is designed for New Zealand employers who are accredited by Immigration New Zealand to hire skilled workers from overseas. It allows these employers to fill specific job roles that cannot be filled by the local workforce.
      </p>
      
      <h2>Requirements</h2>
      <ul>
        <li>The employer must be accredited with Immigration New Zealand.</li>
        <li>The job offer must be for full-time work, offering at least 30 hours per week.</li>
        <li>The position must pay at or above the median wage rate (NZD$29.66 per hour as of February 2023), unless exempt. <a href="https://www.immigration.govt.nz/employ-migrants/new-employer-accreditation-and-work-visa/passing-the-job-check/sector-agreements-and-hiring-migrants-on-an-aewv/roles-exempt-from-aewv-median-wage-threshold" target="_blank" rel="noopener noreferrer">Exempt occupations</a></li>
        <li>The applicant must meet health and character requirements.</li>
        <li>The visa holder can only work in the specific occupation, for the accredited employer, and in the location specified on the visa.</li>
        <li>Any changes to these conditions require applying for a variation of the visa conditions or a Job Change application.</li>
        <li>Working a second job while holding an AEWV is not permitted.</li>
      </ul>
      
      <h2>Employer Commitments</h2>
      <ul>
        <li>Provide specific work-related settlement information and support to the visa holder.</li>
        <li>Allow time for completing study modules about working in New Zealand, if applicable.</li>
        <li>Not pass on accreditation, recruitment, or operational costs to the visa holder.</li>
        <li>Comply with New Zealand employment and immigration laws.</li>
      </ul>
      
      <p>
        Migrant workers under the AEWV have rights equivalent to those of New Zealand workers. If there are concerns about mistreatment or unsafe workplace conditions, it is encouraged to report such issues. Reporting will not impact the visa status of the worker.
      </p>
      
    </div>
  );
};

export default AEWV;
