// src/components/Header.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <Link to="/" onClick={closeMenu}>
        <div className="logo">
          <img src="/logo.svg" alt="Logo" />
        </div>
      </Link>
      <nav className="nav">
        <div className={`menu-toggle ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
          <div className="hamburger"></div>
        </div>
        <ul className={`nav-links ${menuOpen ? 'active' : ''}`}>
          <li className="nav-item">
            <div className="dropdown">
              <span className="nav-link" onClick={closeMenu}>Services</span>
              <ul className="dropdown-menu">
                <li><Link to="/working-holiday-visa" onClick={closeMenu}>Working Holiday Visa</Link></li>
                <li><Link to="/aewv" onClick={closeMenu}>Accredited Employer Work Visa</Link></li>
                <li><Link to="/visitor-visa" onClick={closeMenu}>Visitor Visa</Link></li>
                <li><Link to="/student-visa" onClick={closeMenu}>Student Visa</Link></li>
                <li><Link to="/residence-visa" onClick={closeMenu}>Residence Visa</Link></li>
                <li><Link to="/partnership-visa" onClick={closeMenu}>Partnership Visa</Link></li>
                
              </ul>
            </div>
          </li>
          <li className="nav-item">
            <Link to="/latest-news" className="nav-link" onClick={closeMenu}>Latest News</Link>
          </li>
          <li className="nav-item">
            <Link to="/contact" className="nav-link" onClick={closeMenu}>Contact Us</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
