import { useRef } from "react";

const useScrollRefs = () => {
  const servicesRef = useRef(null);
  const adviserRef = useRef(null);

  return { servicesRef, adviserRef };
};

export default useScrollRefs;
