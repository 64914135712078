// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { Contact, Footer, Header } from "./components";
import Home from "./pages/Home";
import AEWV from "./pages/AEWV/AEWV";  // Update the import to match your file structure

function App() {
  return (
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/aewv" element={<AEWV />} />
        </Routes>
        <Footer />
      </div>
  );
}

export default App;
