import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './contact.css';

const Contact = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [errors, setErrors] = useState({});

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formData.name ? "" : "This field is required.";
    tempErrors.email = (/^[^@\s]+@[^@\s]+\.[^@\s]+$/).test(formData.email) ? "" : "Email is not valid.";
    tempErrors.message = formData.message ? "" : "This field is required.";
    setErrors({...tempErrors});
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const serviceID = 'service_33d4gm7';
      const templateID = 'template_jygau1g';
      const userID = 'fnuywxGRjVs03_ZXd';
      const templateParams = {
        user_name: formData.name,
        user_email: formData.email,
        user_message: formData.message
      };

      try {
        await emailjs.send(serviceID, templateID, templateParams, userID);
        console.log("Email successfully sent! We will be in touch with you soon!");
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
          setFormData({ name: '', email: '', message: '' }); // Reset the form fields
        }, 3000);
      } catch (error) {
        console.error("Error sending email: ", error);
        alert('There was an error sending your message. Please try again later.');
      }
    }
  };

  return (
    <section id="contact" className="contact-form-section">
      <div className="container-headh2">
        <h1>Contact Us</h1>
        <div className="contact-form-content">
          <form onSubmit={handleSubmit}>
            <div className="contact-form-group">
              <input
                type="text"
                name="name"
                placeholder="Name *"
                value={formData.name}
                onChange={handleChange}
                className={errors.name ? 'error' : ''}
              />
              {errors.name && <p className="error-message">{errors.name}</p>}
            </div>
            <div className="contact-form-group">
              <input
                type="email"
                name="email"
                placeholder="Email *"
                value={formData.email}
                onChange={handleChange}
                className={errors.email ? 'error' : ''}
              />
              {errors.email && <p className="error-message">{errors.email}</p>}
            </div>
            <div className="contact-form-group">
              <textarea
                name="message"
                placeholder="Your Message *"
                value={formData.message}
                onChange={handleChange}
                className={errors.message ? 'error' : ''}
                rows="10"
              />
              {errors.message && <p className="error-message">{errors.message}</p>}
            </div>
            <button type="submit" className="submit-button">Send Message</button>
          </form>
          {showPopup && <div className="popup-message success">Your message has been sent successfully!</div>}
        </div>
        <div className="map-container">
          <iframe 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2892.06833291116!2d172.67241977617093!3d-43.542617471107654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6d3189c3f9e2ffb9%3A0x716e9e3cff03bfa5!2s2%20Islay%20Place%2C%20Woolston%2C%20Christchurch%208062!5e0!3m2!1sen!2snz!4v1718800682132!5m2!1sen!2snz"
            width="600" 
            height="450" 
            style={{ border: 0 }} 
            allowFullScreen="" 
            loading="lazy" 
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
