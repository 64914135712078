import React from "react";
import "./links.css"; // Import the CSS file

const Links = () => {
  return (
    <div className="useful-links-container"> {/* Apply the CSS class */}
      <h1>Useful Links:</h1>
      <ul>
        <li>
          <a href="https://www.immigration.govt.nz/">New Zealand Immigration Official Website</a>
        </li>
        <li>
          <a href="https://www.newzealandnow.govt.nz/">New Zealand Now - Official Immigration Guide</a>
        </li>
        <li>
          <a href="https://www.immigration.govt.nz/new-zealand-visas">New Zealand Visas</a>
        </li>
        <li>
          <a href="https://www.immigration.govt.nz/about-us/research-and-statistics/statistics/">Immigration Statistics</a>
        </li>
       
      </ul>
    </div>
  );
};

export default Links;
